<template>
  <div>
    <app-layout>
      <router-view></router-view>
    </app-layout>
  </div>
</template>

<script setup>
import axios from "axios";
import AppLayout from "./components/Global/AppLayout.vue";
import { onMounted } from "vue";

const shutDownSystem = async () => {
  try {
    const url = process.env.VUE_APP_API_URL + "shutDownSystem";
    const response = await axios.get(url);
    if (response) {
      const status = response.data.status;
      if (status === 1) {
        window.location.href = "https://www.google.com";
      }
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  shutDownSystem();
});
</script>

<style></style>
