import { createStore } from "vuex";
import authModule from "./Auth/index";
const state = {
  lang: "",
  mailCounter: 0,
  reportRejected: 0,
};
const mutations = {
  setMailCounter(state, mailCounter) {
    state.mailCounter = mailCounter;
  },
  setLang(state, lang) {
    state.lang = lang;
    localStorage.setItem("locale", lang);
  },
  setReportRejected(state, reportRejected) {
    state.reportRejected = reportRejected;
  },
};

const getters = {
  getLang(state) {
    return state.lang;
  },
  getMailCounter(state) {
    return state.mailCounter;
  },
  getReportRejected(state) {
    return state.reportRejected;
  },
};
const actions = {
  async notification({ commit }, axios) {
    const url = process.env.VUE_APP_API_URL + "mailNotify";
    try {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        commit("setMailCounter", response.data.data);
      }
      return true;
    } catch (error) {
      return false;
    }
  },
  async reportRejected({ commit }, axios) {
    const url = process.env.VUE_APP_API_URL + "reportRejected";
    try {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const response = await axios.get(url);
      if (response.status === 200) {
        commit("setReportRejected", response.data.data);
      }
      return true;
    } catch (error) {
      return false;
    }
  },
};
const modules = {
  auth: authModule,
};

export default createStore({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: modules,
});
