// RoleChecker.js

class RoleChecker {
  constructor(rolesKey) {
    this.rolesKey = rolesKey || "roles";
  }

  getUserRoles() {
    const storedRoles = localStorage.getItem(this.rolesKey);
    if (storedRoles) {
      try {
        return JSON.parse(storedRoles);
      } catch (error) {
        return [];
      }
    }
    return [];
  }

  hasAnyRole(rolesToCheck) {
    const userRoles = this.getUserRoles();
    return rolesToCheck.some((role) => userRoles.includes(role));
  }
  isRole(role) {
    const check = this.hasAnyRole([role]);
    if (check) {
      return true;
    }
    return false;
  }
}

export default new RoleChecker();
