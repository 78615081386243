import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "@/store";
import hasRole from "@/middleware/hasRole";
// import { isAuth } from "@/middleware/auth";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Users/DashBoard.vue"),
    meta: {
      hiddenWhenAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      hiddenWhenAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("../views/SuperAdmin/AccountsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mailbox",
    name: "mailbox",
    component: () => import("../views/Global/MailView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/replay/:id",
  //   name: "replay",
  //   component: () => import("../components/MailBox/RepLay.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/sent/:id",
    name: "sent",
    component: () => import("../components/MailBox/ShowSentEmail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sentArchive/",
    name: "sentArchive",
    component: () => import("../components/MailBox/SentEmailArchived.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recevierArchive/",
    name: "recevierArchive",
    component: () => import("../components/MailBox/RecevierEmailArchived.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/media/",
    name: "media",
    component: () => import("../views/Media/MediaView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chartform/",
    name: "chartform",
    component: () => import("../views/Chart/ChartView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reportview/:query?",
    name: "reportview",
    component: () => import("../views/Chart/ReportsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/:query?",
    name: "reports",
    component: () => import("../views/Users/ReportsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservations",
    name: "reservations",
    component: () => import("../views/SuperAdmin/ReservationAdmin.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ListView",
    name: "ListView",
    component: () => import("../views/Users/ListView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/ReservatyionView",
    name: "ReservatyionView",
    component: () => import("../views/Users/ReservatyionView.vue"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const token = store.getters.getToken;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      next({
        name: "login",
      });
    } else if (
      !hasRole.hasAnyRole([
        "superadmin",
        "staff",
        "dep",
        "admin",
        "sub",
        "media",
        "chart",
      ])
    ) {
      localStorage.clear();
      next({
        name: "login",
      });
    } else {
      next();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  } else if (to.matched.some((record) => record.meta.hiddenWhenAuth)) {
    const isAuth = store.getters.getIsAuth;
    if (isAuth) {
      next({
        name: "dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
