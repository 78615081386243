import axios from "axios";

const authModule = {
  state: {
    email: "",
    username: "",
    roles: [],
    token: "",
    isAuth: false,
  },
  mutations: {
    setAuth(state, data) {
      state.email = data.email;
      state.username = data.name;
      state.roles = data.roles;
      state.token = data.token;
      state.isAuth = true;
      localStorage.setItem("token", data.token);
      localStorage.setItem("email", data.email);
      localStorage.setItem("username", data.name);
      localStorage.setItem("roles", JSON.stringify(data.roles)); // Store roles as JSON string
      localStorage.setItem("isAuth", true);
    },
    initializeStore(state) {
      state.email = localStorage.getItem("email") || "";
      state.username = localStorage.getItem("username") || "";
      state.roles = JSON.parse(localStorage.getItem("roles")) || [];
      state.token = localStorage.getItem("token") || "";
      state.isAuth = localStorage.getItem("isAuth") === "true";
    },
    removeLocalStorage() {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("username");
      localStorage.removeItem("roles");
      localStorage.removeItem("isAuth");
    },
  },
  getters: {
    getEmail(state) {
      return state.email;
    },
    getUsername(state) {
      return state.username;
    },
    getRoles(state) {
      return state.roles;
    },
    getToken(state) {
      return state.token;
    },
    getIsAuth(state) {
      return state.isAuth;
    },
  },
  actions: {
    async login({ commit }, data) {
      const url = process.env.VUE_APP_API_URL + "login";
      const response = await axios.post(url, {
        phone_no: data.phone_no,
        password: data.password,
      });
      commit("setAuth", response.data.data);
      return response.data.data;
    },
    async logout({ commit }, router) {
      const url = process.env.VUE_APP_API_URL + "logout";
      try {
        await axios.post(url, null, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        commit("removeLocalStorage");

        commit("initializeStore");

        router.push({ name: "login" });
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};

export default authModule;
